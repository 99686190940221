@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap");

:root {
  --text-color: #1e1e1e;
  --accent-color: #ff2929;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
a {
  text-decoration: none;
}
ul {
  list-style-type: none;
}
.pixelo-navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    width: 100%;
    /* background-color: skyblue; */
    padding: 0px 60px;
    box-sizing: border-box;
    border-bottom: none;  
}

.pixelo-logo{
    font-size: 30px;
    cursor: pointer;
}
.logo-img{
    height: 50px;
}
.pixelo-navigation{
    display: flex;
    justify-content: space-between;
}
.pixelo-navigation-nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 600px;
    height: 50px;
    margin-right: 0px;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 19px; 
}
.nav-links:hover{
    color: #f13863;
}

.pixelo-nav-home:hover,.pixelo-nav-pricing:hover,.pixelo-nav-about:hover,.pixelo-nav-contact:hover{
    cursor: pointer;
    transition-duration: 0.1s;
    color: #E9204F;
}
.pixelo-navigation-btn{
    display: flex;
    justify-content: space-between;
    width: 200px;
    height: 50px;
}

.pixelo-nav-signup{
    width: 80px;
    height: 40px;
    border-radius: 46px;
    display: flex;
    justify-content: center;
    font-family: 'Roboto', sans-serif;
    align-items: center;
    cursor: pointer;
}

.pixelo-nav-signup{
    background-color: #f62555;
    box-shadow: 0px 20px 30px rgba(249, 5, 62, 0.2);
}
  
.nav-links {
    color: #120f0f;
    cursor: pointer;
  }

.manu-bar-button{
    background-color:aliceblue;
}
.manu-bar-button-img{
    height: 50px;
}

@media screen and (min-width:740px) {
    .manu-bar-button{
        display: none;
    }
}

@media screen and (max-width: 740px){

    .pixelo-navigation-nav{
        display: none;
        /* background-image: url(""); */
    }
 
}
@media screen and (max-width: 300px){
    .pixelo-navigation{
        display: none;
    }
    .pixelo-logo{
        font-size: 40px;
    }
}