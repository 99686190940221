.navbar-body {
    height: auto;
    display: flex;
    justify-content: right;
    align-items: center;
  }
  
.menu-bars-body {
    margin-left: 2rem;
    font-size: 2rem;
    background: none;
  }
  
.nav-menu {
    background-color: #E9204F;
    width: 200px;
    height: 53vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0%;
    right: 100%;
    transition: 0ms;
    border-bottom-left-radius: 10px;
  }
.nav-menu.active {
    right: 0;
    transition: 0ms;
    opacity: 100px;
    z-index: 500;
  }
  
.nav-text {
    display: flex;
    align-items: center;
    padding: 8px 0px 8px 16px;
    list-style: none;
    height: 60px;
  }
  
.nav-text a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
  }
  
.nav-text a:hover {
    background-color: #18191a;
  }
  
.nav-menu-items {
    width: 100%;
  }
  
.navbar-toggle-body {
   background-color: #E9204F;
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
  }
  
span {
    margin-left: 16px;
  }

@media only screen and (min-width: 740px) {
    .navbar-body, .nav-menu.active  {
      display: none;
    }
  }
  